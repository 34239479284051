import UserService from '@/services/user.service'

const user: any = JSON.parse(localStorage.getItem('user') || '{}')
const initialState = user
  ? { status: { loggedIn: true }, user }
  : { status: { loggedIn: false }, user: null }

export const getPayments = {
  namespaced: true,
  state: initialState,
  actions: {
    createUser ({ commit }: any, data: any) {
      return UserService.createUser(data).then(
        response => {
          return Promise.resolve(response.data)
        },
        error => {
          return Promise.reject(error)
        }
      )
    },
    getBalance ({ commit }: any) {
      return UserService.getBalance().then(
        response => {
          return Promise.resolve(response)
        },
        error => {
          return Promise.reject(error)
        }
      )
    },
    viewLetterRequest ({ commit }: any, data: any) {
      return UserService.viewLetterRequest(data).then(
        response => {
          return Promise.resolve(response)
        },
        error => {
          return Promise.reject(error)
        }
      )
    },
    getUsers ({ commit }: any) {
      return UserService.getUsers().then(
        response => {
          return Promise.resolve(response)
        },
        error => {
          return Promise.reject(error)
        }
      )
    },
    getLetters ({ commit }: any) {
      return UserService.getLetters().then(
        response => {
          return Promise.resolve(response)
        },
        error => {
          return Promise.reject(error)
        }
      )
    },
    getCoinRefills ({ commit }: any) {
      return UserService.getCoinRefills().then(
        response => {
          return Promise.resolve(response)
        },
        error => {
          return Promise.reject(error)
        }
      )
    },
    getMarks ({ commit }: any) {
      return UserService.getMarks().then(
        response => {
          return Promise.resolve(response)
        },
        error => {
          return Promise.reject(error)
        }
      )
    },
    getPlaces ({ commit }: any) {
      return UserService.getPlaces().then(
        response => {
          return Promise.resolve(response)
        },
        error => {
          return Promise.reject(error)
        }
      )
    }
  },
  mutations: {
  }
}
